import VueI18n from '@/i18n'

export default Object.freeze({
  BUSINESS: 'business',
  PRIVATE: 'private',
  isBusiness (type) {
    return type === this.BUSINESS
  },
  isPrivate (type) {
    return type === this.PRIVATE
  },
  isValid (type) {
    return this.isBusiness(type) || this.isPrivate(type)
  },
  get types () {
    return [
      this.BUSINESS,
      this.PRIVATE
    ]
  },
  getTranslation (type) {
    return VueI18n.t(`global.customers.types.${type}`)
  }
})
